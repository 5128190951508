import React from 'react';

import type { Task } from '@tf/shared';
import { lighten, TFText, UnstyledButton } from '@tf/ui';

import { TaskIcon } from './TaskIcon';

interface Props {
	task: Task;
	onClick: () => void;
}

export const TaskItem: React.FC<Props> = ({ task, onClick }) => {
	return (
		<UnstyledButton
			sx={({ colors }) => ({
				display: 'flex',
				alignItems: 'center',
				width: '100%',
				padding: '.15rem .85rem',
				fontSize: '.8rem',
				borderTopStyle: 'solid',
				borderTopWidth: '1px',
				borderTopColor: colors.light[2],

				'&:hover': {
					backgroundColor: lighten(colors.brand[1], 0.9),
					color: colors.brand[6],
				},

				'&:first-of-type': {},
			})}
			onClick={onClick}
		>
			<TaskIcon type={task.type} />
			<TFText size="13px" ml=".5rem">
				{task.label}
			</TFText>
		</UnstyledButton>
	);
};
