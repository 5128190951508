import React from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
	Box,
	createStyles,
	TFDataTable,
	TFText,
	UnstyledButton,
	useDebouncedValue,
	useLocalStorage,
} from '@tf/ui';
import { fmt, S } from '@tf/utils';

import { useMonitoringMatchesQuery } from '@/core/api/monitoring';
import { useSelectedMonitoringEntity } from '@/core/hooks';
import { MonitoringCardCommentsButton, MonitoringCardMatchStatus } from '@/components/monitoring';

const useStyles = createStyles(({ colors }) => ({
	fullName: {
		fontSize: 13,
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		color: colors.gray[9],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
}));

type Match = {
	rawResponse: {
		hit: {
			created_utc: string;
			id: string;
			key_information: {
				entity_type: string;
				match_status: string;
				name: string;
				country_names: string;
				date_of_birth: string;
			};
		};
	};
};

export const MonitoringCardMatchesTable = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const params = useParams<{ graphId: string }>();
	const { entityKind } = useSelectedMonitoringEntity();
	const entityId = parseInt(params.graphId as string, 10);
	const isIndividual = entityKind === 'INDIVIDUAL_ENTITY';

	const [pagination, setPagination] = useLocalStorage<{ pageSize: number; pageIndex: number }>({
		defaultValue: { pageSize: 10, pageIndex: 0 },
		key: 'monitoring-matches-table-pagination',
		getInitialValueInEffect: false,
	});

	const [searchValue, setSearchValue] = useLocalStorage<string>({
		defaultValue: '',
		key: 'monitoring-matches-table-search',
		getInitialValueInEffect: false,
	});
	const [debouncedSearch] = useDebouncedValue(searchValue, 500);

	const monitoringMatchesQuery = useMonitoringMatchesQuery({
		limit: pagination.pageSize,
		page: pagination.pageIndex + 1,
		graphNodeId: entityId,
		query: debouncedSearch,
	});

	const matches = (monitoringMatchesQuery.data?.matches as Match[]) ?? [];

	const profiles = matches
		?.map((item) => item.rawResponse.hit)
		?.map((item) => {
			return {
				id: item.id,
				dateOfIncorporation: item?.created_utc,
				matchStatus: item?.key_information?.match_status,
				entityType: item?.key_information?.entity_type,
				fullName: item?.key_information?.name,
				countries: item?.key_information?.country_names,
				dateOfBirth: item?.key_information?.date_of_birth,
			};
		});

	const handleRowClick = (id: string) => {
		navigate({
			pathname: `matches/${id}`,
			search: `?${searchParams.toString()}`,
		});
	};

	return (
		<Box mt="2.5rem">
			<TFDataTable
				name="monitoring-matches-table"
				isLoading={monitoringMatchesQuery.isPending}
				serverPagination={
					matches
						? {
								...pagination,
								rowCount: monitoringMatchesQuery.data?.searchInfo.totalHits ?? 0,
								onPaginationChange: setPagination,
						  }
						: undefined
				}
				serverSearch={{
					state: searchValue,
					onSearchChange: setSearchValue,
				}}
				data={profiles ?? []}
				defs={[
					{
						header: 'Full name',
						accessorKey: 'fullName',
						enableSorting: true,
						size: 200,
						Cell: ({ row }) => {
							const { fullName, id } = row.original;
							return (
								<UnstyledButton className={classes.fullName} onClick={() => handleRowClick(id)}>
									<TFText inherit className={classes.fullName}>
										{fullName || 'N/A'}
									</TFText>
								</UnstyledButton>
							);
						},
					},
					{
						header: 'Entity type',
						accessorKey: 'entityType',
						size: 100,
						Cell: ({ row }) => {
							const { entityType } = row.original;
							return (
								<TFText inherit lineClamp={1}>
									{S.prettify(entityType) || 'N/A'}
								</TFText>
							);
						},
					},
					{
						header: 'Countries',
						accessorKey: 'countries',
						size: 150,
						enableSorting: true,
						Cell: ({ row }) => {
							const { countries } = row.original;
							return (
								<Box>
									<TFText inherit>{countries || 'N/A'}</TFText>
								</Box>
							);
						},
					},
					{
						header: 'Comment',
						accessorKey: 'id',
						size: 150,
						Cell: ({ row }) => {
							const { id } = row.original;
							return (
								<MonitoringCardCommentsButton
									monitoringMatchesQuery={monitoringMatchesQuery}
									entityId={entityId}
									matchId={id}
								/>
							);
						},
					},
					{
						header: isIndividual ? 'Date of birth' : 'Date of incorporation',
						accessorKey: 'dateOfBirth',
						size: 130,
						Cell: ({ row }) => {
							const { dateOfBirth, dateOfIncorporation } = row.original;
							const date = isIndividual ? dateOfBirth : dateOfIncorporation;
							if (date) {
								return (
									<TFText inherit lineClamp={1}>
										{fmt.toDate(date, { preset: 'full_date' })}
									</TFText>
								);
							}
							return (
								<TFText inherit c="dimmed" lineClamp={1}>
									N/A
								</TFText>
							);
						},
					},
					{
						header: 'Match status',
						accessorKey: 'matchStatus',
						size: 200,
						Cell: ({ row }) => {
							const { matchStatus, id } = row.original;
							return (
								<MonitoringCardMatchStatus
									initialMatchStatus={matchStatus}
									entityId={entityId}
									matchId={id}
								/>
							);
						},
					},
				]}
			/>
		</Box>
	);
};
