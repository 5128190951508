import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, Stack } from '@tf/ui';

import { useEntityConnectionLabels } from '@/core/hooks';
import type { ExtendedReviewContainer } from '@/core/types';
import { AddConnectionButton } from '@/components/shared';

import { GroupLabel } from '../GroupLabel';

import { Item } from './Item';

interface Props {
	review: ExtendedReviewContainer;
	fromEntity: BackendTypes.Entity;
	isExternal?: boolean;
}

export const EntityConnections: React.FC<Props> = ({ fromEntity, review, isExternal }) => {
	const connectionLabels = useEntityConnectionLabels(fromEntity.entityKind);

	return (
		<Stack gap={18} mt="1rem">
			{fromEntity.listConnections
				.sort((a, b) => a.connectionKind.localeCompare(b.connectionKind))
				.map((item) => {
					const count = item.listFromEntities.length;
					const hasConnections = count > 0;

					const { singular: label, plural: groupLabel } = connectionLabels[item.connectionKind];

					return (
						<Box key={item.connectionKind}>
							<GroupLabel
								label={groupLabel}
								isRequired={item.isLogicalRequired}
								actions={
									hasConnections && (
										<AddConnectionButton
											buttonKind="icon"
											label={label}
											review={review}
											connectionKind={item.connectionKind}
											parentEntity={{
												graphId: fromEntity.graphNodeId,
												kind: fromEntity.entityKind,
											}}
										/>
									)
								}
							/>
							{hasConnections ? (
								<Stack gap="sm">
									{item.listFromEntities.map((e) => {
										const key = `${item.connectionKind}.${e.graphNodeId}`;
										return (
											<Item
												key={key}
												item={e}
												review={review}
												fromGraphId={fromEntity.graphNodeId}
												connectionKind={item.connectionKind}
												isExternal={!!isExternal}
											/>
										);
									})}
								</Stack>
							) : (
								<AddConnectionButton
									label={label}
									review={review}
									connectionKind={item.connectionKind}
									parentEntity={{ graphId: fromEntity.graphNodeId, kind: fromEntity.entityKind }}
									buttonProps={{ mt: '.25rem' }}
								/>
							)}
						</Box>
					);
				})}
		</Stack>
	);
};
