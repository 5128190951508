import React from 'react';

import { Link } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import {
	Box,
	Button,
	createStyles,
	TFDataTable,
	TFLoadingOverlay,
	TFText,
	Title,
	UnstyledButton,
	useDisclosure,
} from '@tf/ui';
import { fmt, S } from '@tf/utils';

import { useClientCasesRequests } from '@/core/api/caseRequests';
import { useAccountParams } from '@/core/hooks';
import { getDisplayName } from '@/core/utils';

import { CreateCaseModal } from './CreateCaseModal';
import { clientCaseKindLabels, clientCaseKindOptions } from './requestKind';

const useStyles = createStyles(({ colors }) => ({
	link: {
		fontSize: 13,
		height: 32,
		display: 'flex',
		alignItems: 'center',
		color: colors.brand[6],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
	updatedBadge: {
		backgroundColor: '#EFF4FF',
		color: '#164CB0',
		borderRadius: '20px',
		padding: '0 8px',
		position: 'relative',
		'& > div': {
			position: 'absolute',
			top: -1,
			right: -1,
			backgroundColor: 'red',
			width: '8px',
			height: '8px',
			borderRadius: '50%',
			border: '1.5px solid white',
		},
	},
	sentBadge: {
		backgroundColor: '#F4EFFF',
		color: '#403E94',
		borderRadius: '20px',
		padding: '0 8px',
	},
	defaultBadge: {
		backgroundColor: '#F3F3F3',
		color: '#2B2B2B',
		borderRadius: '20px',
		padding: '0 8px',
	},
}));

export const ClientCasesTable = () => {
	const { accountId } = useAccountParams();
	const { data, isSuccess } = useClientCasesRequests(accountId);
	const createClientCaseModal = useDisclosure();
	const { classes } = useStyles();

	if (!isSuccess) {
		return <TFLoadingOverlay h={300} label="Cases loading..." />;
	}

	return (
		<Box mt="1.5rem">
			<Box mb="0.75rem" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Title order={3}>Cases</Title>
				<Button onClick={createClientCaseModal.open}>Create case</Button>
			</Box>

			<TFDataTable<BackendTypes.ClientCaseRequest>
				name="cases-table"
				data={data}
				defs={[
					{
						header: 'ID',
						accessorKey: 'sequential_id',
						size: 50,
						Cell: ({ row: { original } }) => {
							return (
								<UnstyledButton component={Link} to={original.uuid} className={classes.link}>
									<TFText inherit>{formatID(original.sequential_id)}</TFText>
								</UnstyledButton>
							);
						},
					},
					{
						header: 'Type',
						accessorKey: 'requestKind',
						size: 100,
						filterFn: (row, columnId, filterValue) => {
							return row.original.requestKind === filterValue;
						},
						Cell: ({ row }) => {
							const { requestKind } = row.original;
							return <TFText inherit>{clientCaseKindLabels[requestKind]}</TFText>;
						},
					},
					{
						header: 'Client email',
						accessorKey: 'clientEmail',
						size: 120,
					},
					{
						header: 'Sent date',
						id: 'createdAt',
						size: 100,
						accessorFn: (request) => {
							return request.sentAt ? fmt.toDate(request.sentAt, { preset: 'full_date' }) : 'N/A';
						},
					},
					{
						header: 'Due date',
						id: 'dueAt',
						size: 85,
						accessorFn: (request) => {
							return request.dueAt ? fmt.toDate(request.dueAt, { preset: 'full_date' }) : 'N/A';
						},
					},
					{
						header: 'Author',
						id: 'authorUserInfo',
						size: 70,
						accessorFn: (request) => {
							return request.authorUserInfo ? getDisplayName(request.authorUserInfo) : 'N/A';
						},
					},
					{
						header: 'Status',
						id: 'status',
						accessorKey: 'status',
						size: 70,
						Cell: ({ row: { original } }) => {
							if (original.status === 'UPDATED') {
								return (
									<Box className={classes.updatedBadge}>
										{S.prettify(original.status)}
										<Box />
									</Box>
								);
							}
							if (original.status === 'SENT') {
								return <Box className={classes.sentBadge}>{S.prettify(original.status)}</Box>;
							}
							return <Box className={classes.defaultBadge}>{S.prettify(original.status)}</Box>;
						},
					},
				]}
				availableFilters={[
					{
						name: 'status',
						label: 'Status',
						type: 'select',
						value: [
							{ value: 'DRAFT', label: 'Draft' },
							{ value: 'SENT', label: 'Sent' },
							{ value: 'VIEWED', label: 'Viewed' },
							{ value: 'UPDATED', label: 'Updated' },
							{ value: 'CLOSED', label: 'Closed' },
						],
					},
					{
						name: 'requestKind',
						label: 'Type',
						type: 'select',
						value: clientCaseKindOptions,
					},
				]}
			/>

			<CreateCaseModal
				onClose={createClientCaseModal.close}
				opened={createClientCaseModal.isOpen}
				title="Create a new case"
				contentProps={{
					close: createClientCaseModal.close,
				}}
			/>
		</Box>
	);
};

function formatID(id?: number) {
	if (!id) return;
	const idStr = id.toString().padStart(6, '0').split('');

	for (let i = idStr.length - 3; i > 0; i -= 3) {
		idStr.splice(i, 0, '-');
	}

	return idStr.join('');
}
