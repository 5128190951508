import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Box, createStyles, Tabs } from '@tf/ui';
import { S } from '@tf/utils';

import { useClientCasesRequests } from '@/core/api/caseRequests';
import { useAccountParams, useEntityConnectionLabels, useSelectedAccount } from '@/core/hooks';
import { CounterBadge } from '@/components/shared';

interface RouteBinding {
	path: string;
	label: string;
	count?: number;
}

const useStyles = createStyles(({ colors, breakpoints }) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: breakpoints.xl,
		margin: '0 auto',
		color: colors.light[7],
		borderWidth: '0 0 1px 0',
		borderStyle: 'solid',
		borderColor: colors.light[2],
	},
	tab: {
		display: 'flex',
		gap: '.35rem',
	},
}));

export const AccountTabs = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { classes } = useStyles();
	const { accountId } = useAccountParams();
	const account = useSelectedAccount();

	const connectionLabels = useEntityConnectionLabels(account.meta.entityKind);

	const connections = account.meta.listConnections.filter((c) => c.isReview);
	const path = `/${location.pathname.split('/').slice(3, 4).join('/')}`;
	const { data: clientCases } = useClientCasesRequests(accountId);

	const routeBindings: RouteBinding[] = [
		{
			path: '/',
			label: 'Overview',
		},
		...(connections || [])
			.map(({ connectionKind: kind }) => {
				const count = account.reviews.filter((r) => r.connectionKind === kind).length;

				return {
					path: `/${S.slugify(kind)}`,
					label: connectionLabels[kind].plural,
					count,
				};
			})
			.sort((a, b) => a.label.localeCompare(b.label)),
	];

	return (
		<Box className={classes.root}>
			<Tabs
				variant="normal"
				value={path}
				styles={({ colors }) => ({
					root: { flexGrow: 1 },
					tab: {
						padding: '.65rem 1rem',
						borderBottomWidth: 1.5,
						borderRadius: 0,
						"&[data-active='true']": {
							fontWeight: 500,
							color: colors.brand[6],
							backgroundColor: colors.brand[0],
							borderBottom: `1.5px solid ${colors.brand[6]}`,
						},
					},
					list: { borderBottomWidth: 0 },
				})}
				onChange={(value) => navigate(`/accounts/${accountId}${value}`)}
			>
				<Tabs.List>
					{routeBindings.map(({ path, label, count }) => {
						return (
							<Tabs.Tab key={path} value={path}>
								<Box className={classes.tab}>
									{label}
									{count ? <CounterBadge value={count} /> : null}
								</Box>
							</Tabs.Tab>
						);
					})}
					<Tabs.Tab value={'/cases'}>
						<Box className={classes.tab}>
							Cases
							{clientCases?.length ? <CounterBadge value={clientCases.length} /> : null}
						</Box>
					</Tabs.Tab>
				</Tabs.List>
			</Tabs>
		</Box>
	);
};
