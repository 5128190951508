import React from 'react';

import {
	Box,
	createStyles,
	lighten,
	TFText,
	Tooltip,
	UnstyledButton,
	type UnstyledButtonProps,
} from '@tf/ui';

interface Props extends UnstyledButtonProps {
	label: React.ReactNode;
	isSelected?: boolean;
	leftSection?: React.ReactNode;
	rightSection?: React.ReactNode;
	onClick?: () => void;
	tooltip?: string;
	disabled?: boolean;
}

const useStyles = createStyles(({ colors }) => ({
	root: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '0 .25rem 0 .75rem',
		height: 40,
		color: colors.brand[5],

		borderTopWidth: 1,
		borderTopStyle: 'solid',
		borderTopColor: colors.light[2],

		borderLeftWidth: 2,
		borderLeftStyle: 'solid',
		borderLeftColor: 'transparent',

		borderCollapse: 'separate',

		'&:hover': {
			backgroundColor: lighten(colors.brand[1], 0.9),
			borderLeftColor: lighten(colors.brand[6], 0.6),
			color: lighten(colors.brand[5], 0.2),
		},

		'&[data-selected="true"]': {
			cursor: 'default',
			backgroundColor: lighten(colors.brand[1], 0.7),
			borderLeftColor: lighten(colors.brand[6], 0.05),
		},

		'&:first-of-type': {
			borderTopWidth: 0,
			borderTopRightRadius: 8,
			borderTopLeftRadius: 8,
		},

		'&:last-of-type': {
			borderBottomRightRadius: 8,
			borderBottomLeftRadius: 8,
		},
	},

	button: {
		height: 40,
		flex: 1,
		fontSize: '.8rem',
		padding: '0 0 0 .5rem !important',
		color: colors.gray[9],
		'&:hover': {
			color: colors.brand[6],
		},
		'&[data-selected="true"]': {
			cursor: 'default',
			color: `${colors.brand[6]} !important`,
		},
	},
}));

export const SideMenuItem: React.FC<Props> = ({
	label,
	isSelected,
	leftSection,
	rightSection,
	tooltip,
	...props
}) => {
	const { classes } = useStyles();

	return (
		<Tooltip label={tooltip} disabled={!tooltip} openDelay={1000} position="right">
			<Box className={classes.root} data-selected={isSelected}>
				{leftSection}
				<UnstyledButton
					className={classes.button}
					data-selected={isSelected}
					disabled={props.disabled || isSelected}
					{...props}
				>
					<TFText size="13px" lineClamp={1}>
						{label}
					</TFText>
				</UnstyledButton>
				{rightSection}
			</Box>
		</Tooltip>
	);
};
