import { Box, createStyles, TFText } from '@tf/ui';

import type { ExtendedReviewContainer } from '@/core/types';
import { getLabel } from '@/core/utils';
import { ReviewTransitionButton } from '@/components/reviews';

interface Props {
	review: ExtendedReviewContainer;
}

const useStyles = createStyles(({ colors }) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'default',
		userSelect: 'none',
		fontWeight: 500,
		fontSize: '0.8rem',
		padding: '.5rem .25rem .5rem 1.25rem',
		color: colors.light[5],
		'&[data-state="DRAFT"]': {
			color: colors.gray[6],
			backgroundColor: colors.gray[1],
		},
		'&[data-state="READY_TO_REVIEW"]': {
			color: colors.brand[4],
			backgroundColor: colors.brand[0],
		},
		'&[data-state="UPDATE_REQUIRED"]': {
			color: colors.red[6],
			backgroundColor: colors.red[0],
		},
		'&[data-state="VERIFIED"]': {
			color: colors.green[8],
			backgroundColor: colors.green[0],
		},
	},
	label: {
		flex: 1,
	},
}));

export const MenuLabel: React.FC<Props> = ({ review }) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.root} data-state={review.state}>
			<TFText size="sm" fw="500" className={classes.label}>
				Status: {getLabel(review.state, { kind: 'reviewState' })}
			</TFText>
			<ReviewTransitionButton review={review} />
		</Box>
	);
};
