import { useCallback } from 'react';

import { useAuth, useClerk } from '@clerk/clerk-react';
import { useSelector } from '@datagrid/state';
import * as Sentry from '@sentry/react';
import { decodeJwt } from 'jose';
import type { JWTPayload } from 'jose/dist/types/types';

import type { BackendTypes } from '@tf/api';

import { configStore } from '@/core/stores';

interface TokenResult {
	value: string;
	tenant: string;
	userId: string;
}

type JWTMembershipMetadata = JWTPayload & {
	org_name: string;
	org_member_metadata: { capabilities: BackendTypes.RoleCapabilityValue[] };
};

const hasMembershipMetadata = (claims: JWTPayload): claims is JWTMembershipMetadata => {
	if (typeof claims['org_name'] !== 'string') {
		return false;
	}

	if ('org_member_metadata' in claims) {
		const orgMetadata = claims.org_member_metadata as any;
		if ('capabilities' in orgMetadata) {
			return Array.isArray(orgMetadata.capabilities) && orgMetadata.capabilities.length > 0;
		}
	}

	return false;
};

export const useGetTenantToken = () => {
	const config = useSelector(() => configStore.get());
	const { getToken, signOut } = useAuth();
	const clerk = useClerk();

	// TODO: should get tenant from TDM
	const requiredTenant = import.meta.env.DEV
		? 'dev1'
		: window.location.hostname.replace('.trustform.io', '').toLowerCase();

	const getParsedToken = useCallback(async (): Promise<TokenResult | null> => {
		const token = await getToken({ template: config.auth.jwt_template });

		if (!token) {
			return null;
		}

		const claims = decodeJwt(token);
		if (!claims.sub || !hasMembershipMetadata(claims)) {
			return null;
		}

		return {
			value: token,
			userId: claims.sub,
			tenant: claims['org_name'],
		};
	}, [config.auth.jwt_template, getToken]);

	return async function retrieveToken() {
		const token = await getParsedToken();

		if (token?.tenant.toLowerCase() === requiredTenant) {
			return token;
		}

		// No token or it belongs to another org, let's try to switch to required org
		const userMemberships = clerk.user?.organizationMemberships;
		const requiredMembership = userMemberships?.find((m) => {
			return m.organization.name.toLowerCase() === requiredTenant;
		});

		if (!requiredMembership) {
			// user is not a member of required organization
			return null;
		}

		await clerk.setActive({ organization: requiredMembership.organization });

		const secondToken = await getParsedToken();
		// Double-check: in rare cases token is still linked to previous tenant after switching
		if (!secondToken || secondToken.tenant.toLowerCase() !== requiredTenant) {
			Sentry.captureMessage('Invalid token after organization switch', 'error');
			await signOut();
			return null;
		}

		return secondToken;
	};
};
