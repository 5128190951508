import { forwardRef } from 'react';

import { createStyles, UnstyledButton } from '@tf/ui';
import { fmt } from '@tf/utils';

const useStyles = createStyles(() => {
	return {
		button: {
			fontWeight: 600,
			borderBottom: '1px dotted',
			whiteSpace: 'nowrap',
		},
		value: {
			fontWeight: 600,
		},
	};
});

interface Props {
	value: string | undefined;
	onClick?: () => void;
	style?: React.CSSProperties;
	defaultValue?: string;
}

export const DateValue = forwardRef<any, Props>(
	({ value, style, defaultValue = 'N/A', onClick }, ref) => {
		const displayValue = value ? fmt.toDate(value ?? null, { preset: 'full_date' }) : defaultValue;

		const { classes } = useStyles();

		return onClick ? (
			<UnstyledButton style={style} ref={ref} className={classes.button} onClick={onClick}>
				{displayValue}
			</UnstyledButton>
		) : (
			<span ref={ref} className={classes.value}>
				{displayValue}
			</span>
		);
	}
);
