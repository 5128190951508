import React from 'react';

import type { FormStatuses } from '@tf/shared';
import { createStyles, Group, Icon, type Sx, TFText, Tooltip } from '@tf/ui';

import { useSelectedReview } from '@/core/hooks';

interface Props {
	status: FormStatuses;
	isDecisionRequired: boolean;
}

const badgeStyles: Sx = {
	flexWrap: 'nowrap',
	borderRadius: 4,
	height: 36,
	minWidth: 36,
	padding: '0 12px',
	cursor: 'default',
	userSelect: 'none',
};

const useStyles = createStyles(({ colors, fontSizes }) => ({
	icon: {
		flexShrink: 0,
	},
	text: {
		fontWeight: 500,
		fontSize: fontSizes.sm,
	},
	valid: {
		backgroundColor: colors.brand[0],
		color: colors.brand[6],
		...badgeStyles,
	},
	verified: {
		backgroundColor: colors.green[0],
		color: colors.green[6],
		...badgeStyles,
	},
	errors: {
		backgroundColor: colors.red[0],
		color: colors.red[5],
		...badgeStyles,
	},
	pending: {
		backgroundColor: colors.light[1],
		color: colors.light[4],
		...badgeStyles,
	},
}));

const State: React.FC<{
	className: string;
	text: string;
	iconComponent: React.ComponentType<{ className: string; size: number }>;
}> = ({ className, text, iconComponent: IconComponent }) => {
	const { classes } = useStyles();

	return (
		<Tooltip label={text}>
			<Group gap={6} className={className}>
				<IconComponent className={classes.icon} size={14} />
				<TFText className={classes.text} truncate="end">
					{text}
				</TFText>
			</Group>
		</Tooltip>
	);
};

export const FormStatusLabel: React.FC<Props> = ({ status, isDecisionRequired }) => {
	const { classes } = useStyles();
	const review = useSelectedReview();

	if (review.state !== 'DRAFT') {
		switch (status.review) {
			case 'ACCEPTED':
				return (
					<State
						iconComponent={Icon.IconCircleCheck}
						className={classes.verified}
						text="Form verified by Compliance"
					/>
				);
			case 'REJECTED':
				return (
					<State
						iconComponent={Icon.IconCircleX}
						className={classes.errors}
						text="Form rejected by Compliance"
					/>
				);
			default:
				return isDecisionRequired ? (
					<State
						iconComponent={Icon.IconClock}
						className={classes.valid}
						text="Form awaits verification"
					/>
				) : null;
		}
	}

	switch (status.structure) {
		case 'OK':
			return (
				<State
					iconComponent={Icon.IconCircleCheck}
					className={classes.valid}
					text="Form completed and ready for review"
				/>
			);
		case 'VALIDATION_ERRORS':
			return (
				<State
					iconComponent={Icon.IconAlertCircle}
					className={classes.errors}
					text="Form contains errors"
				/>
			);
		case 'PENDING_FILL':
			return (
				<State
					iconComponent={Icon.IconCircleDashed}
					className={classes.pending}
					text="Form waiting to be completed"
				/>
			);
		default:
			return null;
	}
};
